<template>
    <div class="mobile-recommended-carousel">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide v-for="memod in similarMemos" :key="memod.id">
                <memod-card
                    :memod="memod"
                    @toggled-like="(data) => $emit('toggled-like', data, memod.id)"
                    @toggled-save="(is_saved) => $emit('toggled-save', is_saved, memod.id)"
                />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
    name: "MobileRecommendedCarousel",
    components: {
        Swiper,
        SwiperSlide,
        MemodCard: () => import(/* webpackChunkName: "memod-card" */ "@c/molecules/memod-card")
    },
    props: {
        similarMemos: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                    el: ".swiper-pagination"
                },
                // loop: true,
                slidesPerView: 2,
                slidesPerGroup: 1,
                spaceBetween: 15,
                threshold: 50,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true
                },
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
                slidesOffsetAfter: 250,
                breakpoints: {
                    // 991: {
                    //     slidesPerView: 3,
                    //     slidesPerGroup: 2
                    // },
                    767: {
                        slidesPerView: 3,
                        slidesPerGroup: 2
                    }
                }
                // on: {
                //     slideChange() {
                //         this.loopDestroy();
                //         this.loopCreate();
                //     }
                // }
            }
        };
    }
}
</script>

<style lang="scss" scoped>
.mobile-recommended-carousel {
    /deep/ .swiper-container {
        @media (max-width: 991px) {
            width: 130%;
        }

        @media (max-width: 766px) {
            width: 112%;
        }

        @media (max-width: 580px) {
            width: 128%;
        }

        @media (max-width: 470px) {
            width: 145%;
        }

        @media (max-width: 400px) {
            width: 165%;
        }

        @media (max-width: 350px) {
            width: 195%;
        }
    }
}
</style>
